import React, { useState } from "react";

const Pricing = ({ plans }) => {
  const [hoverEffect, setHoverEffect] = useState(1);
  return (
    <>
      <div className="page-content bg-white">
        <div className="row">
          {plans?.map((plan, ind) => (
            <div className="col-lg-6 col-md-6 m-b30" key={ind}>
              <div
                className={`pricingtable-wrapper box-hover style-1 ${
                  ind === hoverEffect ? "active" : ""
                }`}
                onMouseEnter={() => setHoverEffect(ind)}
              >
                <div className="pricingtable-inner">
                  <div className={`pricingtable-title ${plan.prime}`}>
                    {plan.title}
                  </div>

                  <div className="pricingtable-price">
                    <h2 className="pricingtable-bx text-primary">
                      ${plan.price}
                    </h2>
                    <p>{plan.description}</p>
                  </div>
                  <ul className="pricingtable-features">
                    <li>Payments are made by direct debit system</li>
                  </ul>
                  <div className="pricingtable-footer">
                    <a
                      href={plan.paylink}
                      className="btn btn-primary btn-skew"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Pay now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Pricing;
