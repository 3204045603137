import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import ClientSlider from '../components/ClientSlider';
import { IMAGES, SVGICON } from '../constants/theme';
import LatestSlider from '../elements/LatestSlider';
import PageTitle from '../elements/PageTitle';
import { servicesData as wrapperBlog } from '../constants/data';



const Services = () => {
    const [hover, setHover] = useState(0);
    return (
        <>
            <div className="page-content bg-white">
                <PageTitle activePage="Services" parentTitle="Home" to="/#services"/>
                <section className="content-inner overflow-hidden" style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <div className="container">
                        <div className="row">
                            {wrapperBlog.map((item, index)=>(
                                <div className="col-xl-3 col-md-6 m-b30" key={index}>
                                    <div className={`icon-bx-wraper style-1 box-hover ${hover === index ? 'active' : ''}`}
                                        onMouseEnter={()=>setHover(index)}
                                    >
                                        <div className="icon-bx m-b30"> 
                                            <span className="icon-cell">
                                                <img src={item.image} alt={item.title} style={{
                                        width: '70px',
                                        height: '70px'
                                    }}/>
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dz-title m-b10"><Link to={"#"}>{item.title}</Link></h5>
                                            <p className="m-b25" style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{item.description}</p>
                                            <Link to={`/services-details/${index}`} className="btn btn-primary shadow-primary btn-skew"><span>Read More</span></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>    
                    </div>
                </section>
       
                {/* <section className="content-inner-1 testimonial-wrapper1" data-text="FEEDBACK" style={{backgroundImage: "url("+ IMAGES.BgImage2 +")", backgroundPosition: "center"}}>
                    <div className="container">
                        <div className="section-head text-center">
                            <h5 className="sub-title">TESTIMONIAL</h5>
                            <h2 className="title">What Client Say’s</h2>
                        </div>
                        <ClientSlider />

                    </div>
                    <div className="avatar1"><img src={IMAGES.avatarlarge1} alt=""/></div>
                    <div className="avatar2"><img src={IMAGES.avatarlarge2} alt=""/></div>
                    <div className="avatar3"><img src={IMAGES.avatar3} alt=""/></div>
                    <div className="avatar4"><img src={IMAGES.avatarlarge1} alt=""/></div>
                    <div className="avatar5"><img src={IMAGES.avatarlarge2} alt=""/></div>
                    <div className="avatar6"><img src={IMAGES.avatar3} alt=""/></div>
                    <img className="svg-shape rotate-360" src={SVGICON.circlebigSvg1} alt=""/>
                    <img className="svg-shape-2 rotate-360" src={SVGICON.circlebigSvg2} alt=""/>
                </section>   */}
                <section className="content-inner-1 overflow-hidden" style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <LatestSlider />
                </section>  
            </div>   
        </>
    );
};

export default Services;