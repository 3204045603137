import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { pillars } from "../constants/data";

function changeItemBoxed() {
  let bodyClass = document.body.classList;

  if (bodyClass.contains === "boxed") {
    return 3;
  } else {
    return 4;
  }
}

function LatestSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);
  return (
    <div className="container">
      <div className="row justify-content-between align-items-center m-b10">
        <div className="col-xl-7">
          <div className="section-head text-center text-md-start">
            <span className="sub-title">OUR PILLARS</span>
            <h2 className="title">
              Performance Hub <span>Pillars</span>
            </h2>
          </div>
        </div>
        <div className="col-xl-5 text-md-end d-flex align-items-center justify-content-xl-end justify-content-sm-between justify-content-center m-sm-b30 m-b40">
          <div className="num-pagination">
            <div className="swiper-blog-prev btn-prev" ref={navigationPrevRef}>
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div
              className="swiper-blog-pagination1 swiper-pagination style-1"
              ref={paginationRef}
            ></div>
            <div className="swiper-blog-next btn-next" ref={navigationNextRef}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        className="swiper blog-slider-full blog-slider-wrapper"
        centeredSlides={true}
        slidesPerView={changeItemBoxed()}
        spaceBetween={30}
        loop={true}
        speed={1500}
        autoplay={{
          delay: 3000,
        }}
        navigation={{
          nextEl: ".btn-next",
          prevEl: ".btn-prev",
        }}
        // onSwiper={(swiper) => {
        //   setTimeout(() => {
        //     swiper.params.navigation.prevEl = navigationPrevRef.current;
        //     swiper.params.navigation.nextEl = navigationNextRef.current;
        //     swiper.navigation.destroy();
        //     swiper.navigation.init();
        //     swiper.navigation.update();
        //   });
        // }}
        modules={[Navigation]}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          320: {
            slidesPerView: 1,
            centeredSlides: false,
          },
        }}
      >
        {pillars?.map((item, ind) => (
          <SwiperSlide key={ind}>
            <div className="dz-card style-1 overlay-shine">
              <div className="dz-media">
                <Link to={`/pillars/${ind}`}>
                  <img src={item.image1} alt="" />
                </Link>
              </div>
              <div className="dz-info">
                <h4 className="dz-title">
                  <Link to={`/pillars/${ind}`}>{item.title}</Link>
                </h4>
                <p>{item.cardDetail}</p>
                <Link
                  to={`/pillars/${ind}`}
                  className="btn btn-primary btn-skew"
                >
                  <span>Read More</span>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
export default LatestSlider;
