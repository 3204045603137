export const MenuListArray2 = [
    {
        title: 'Home',
    },
    {
        title: 'Values',
        alias: 'values',
        to: '/#values'
    },
    {
        title: 'Pillars',
        alias: 'pillars',
        classChange: 'sub-menu-down',
        content: [
            {
                title: 'Neuroscience in Sports',
                to: '/pillars/3',
            },
            {
                title: 'Strength Development',
                to: '/pillars/0',
            },
            {
                title: 'Speed Development', 
                to: '/pillars/1',
            },
            {
                title: 'Football Skills', 
                to: '/pillars/2',
            },

        ],
    },
    {
        title: 'Team',
        alias: 'team',
        to: '/#team',
    },

    {
        title: 'Services',
        alias: 'services',
        classChange: 'sub-menu-down',
        content: [
            {
                title: 'Performance Hub Sessions',
                to: '/services-details/0',
            },
            {
                title: 'Skills and Shooting Sessions',
                to: '/services-details/1',
            },
            {
                title: 'Semiprivate Training Sessions',
                to: '/services-details/2',
            },
            {
                title: 'Athlete Online Programming',
                to: '/services-details/3',
            },
            {
                title: 'Programs for Clubs/Academies/\nSchools',
                to: '/services-details/4',
            },
            {
                title: 'Sports Science Teams Consultancy',
                to: '/services-details/5',
            },
            {
                title: 'Tactical and Physical Game Analysis',
                to: '/services-details/6',
            },

        ],
    },
    {
        title: 'Contact Us',
        alias: 'contact-us',
        to: '/contact-us',
    },
]