import { IMAGES } from "./theme";

export const servicesData = [
  {
    id: 0,
    image: IMAGES.boxRocket,
    banner: IMAGES.service1,
    title: "PERFORMANCE HUB SESSIONS",
    description:
      "Our sessions are structured combining two (2) of our Pillars withing 60 min duration.",
    descriptionList: [
      "Sessions have a duration of 60 min with a maximum capacity of 12 players.",
      "Sessions have at least 2 coaches per session.",
      "Sessions are designed to help players to improve performance without interfering their club development and/or their weekly physical load.",
      "Each player can make up a session within the same week if misses his/her priority choice of the session/s."
    ],
    paid: true,
    plans: [
      {
        price: 45,
        title: "once/week",
        description: "once/week per athlete per session.",
        paylink: "https://buy.stripe.com/aEUdRN0HJevN86I28b"
      },
      {
        price: 35,
        title: "twice/week",
        description: "twice/week per athlete per session.",
        paylink: "https://buy.stripe.com/5kAdRN1LN4Vd4Uw3ce"
      }
    ]
  },
  {
    id: 1,
    image: IMAGES.boxFootball,
    banner: IMAGES.service2,
    title: "SKILLS AND SHOOTING SESSIONS",
    description:
      "Designed for youth players aiming to elevate their football abilities.",
    descriptionList: [
      "Sessions delivered by Coach Lucas Vaz (Pro Player)",
      "Technical skills",
      "Ball control",
      "Precision shooting",
      "Dribbling",
      "Each player can make up a session within the same week if misses his/her priority choice of the session/s."

    ],
    paid: true,
    plans: [
      {
        price: 45,
        title: "once/week",
        description: "once/week per athlete per session.",
        paylink: "https://buy.stripe.com/aEU151fCDbjB72E148",
      }
    ]
  },
  {
    id: 2,
    image: IMAGES.boxTraining,
    banner: IMAGES.service3,
    title: "SEMIPRIVATE TRAINING SESSIONS",
    description:
      "Training sessions for individual and group athletes are designed to optimize physical capabilities across sports, whether you prefer one-on-one attention or small group training (up to 6 athletes). Soccer players can also refine their skills with focused field sessions, ensuring peak performance on the pitch.",
    descriptionList: [
      "Neuroscience in Sports",
      "Speed Development",
      "Strength Development",
      "Skills Mastery",
      "Recovery Sessions (Massage and Mobility Session and/or Normatec Boots available on request)",
      "Tactical Performance Analysis (football if requested)",
      "Suitable for athletes aged 12 to senior players.",
      "Welcoming both male and female athletes.",
      "Any Sport, Any Level: Whether you&#39;re a beginner or a seasoned pro, our programs are designed to meet your needs.",
      "Minimum of two sessions per fortnight to ensure continuous improvement.",
    ],
    paid: false,
  },
  {
    id: 3,
    image: IMAGES.boxAthlete,
    banner: IMAGES.service4,
    title: "ATHLETE ONLINE PROGRAMMING",
    description:
      "Designed for athletes who are looking to improve their performance, the ATHLETE ONLINE PROGRAMMING offers a range of benefits, including:",
    descriptionList: [
      "Tailored Training Programs: Customized programs to meet athletes ‘needs and goals.",
      "Expert Coaching: Access to Zoom meetings with qualified coaches for guidance and support.",
      "Progress Tracking: Tools to monitor and evaluate your performance over time.",
      "Suitable for All Levels: Designed for athletes at any stage of their fitness journey.",
      "Cutting-Edge Technology: Utilizes the latest in training and performance technology to interpret data if available (GPS, Oura Ring, WHOOP).",
    ],
    paid: false,
  },
  {
    id: 4,
    image: IMAGES.boxlog3,
    banner: IMAGES.service5,
    title: "PROGRAMS FOR CLUBS/ACADEMIES/\nSCHOOL",
    description:
      "Bring one of our programs into your club/academy/school for youth or senior athletic development",
    descriptionList: [],
    paid: false,
  },
  {
    id: 5,
    image: IMAGES.boxConsultancy,
    banner: IMAGES.service6,
    title: "SPORTS SCIENCE TEAMS CONSULTANCY",
    description:
      "Performance Hub specializes in sport science consultancy tailoring expert services to enhance the performance of sports teams by:",
    descriptionList: [
      "Pre-Season Testing",
      "Programming a macrocycle plan",
      "In-Season session’s delivering",
      "Interpreting GPS data",
      "Managing player’s wellness",
      "Injury risk management",
    ],
    paid: false,
  },
  {
    id: 6,
    image: IMAGES.boxAnalisys,
    banner: IMAGES.service7,
    title: "TACTICAL AND PHYSICAL GAME ANALYSIS",
    description:
      "Performance Hub provides an entire tactical and physical analysis for player’s match videos (VEO). This analysis is done by experts from PLAYER ADS (current partners servicing Argentina’s first division players and clubs).",
    descriptionList: [
      "Age Range: Suitable for athletes aged 12 to senior players.",
      "Gender Inclusive: Welcoming both male and female athletes.",
      "Sport: Football",
    ],
    paid: false,
  },
];

export const testimonials = [
  {
    name: "Full Name 1",
    image: IMAGES.avatarlarge1,
    socialLink: "https://www.facebook.com/",
    position: "Founder",
    opinion: "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },

  {
    name: "Full Name 2",
    image: IMAGES.avatarlarge2,
    socialLink: "https://www.instagram.com/",
    position: "CEO",
    opinion: "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    name: "Full Name 3",
    image: IMAGES.avatarlarge3,
    socialLink: "https://www.x.com/",
    position: "Manager",
    opinion: "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

export const pillars = [
  {
    id: 0,
    image1: IMAGES.pillars1,
    banner: IMAGES.pillarsStrength,
    title: "Strength Development",
    title2: "Strength Development: Essential for Athletic Success",
    text1: " Strength training is a crucial component of any athlete's training program. At Performance Hub, we focus on developing strength in athletes to improve their performance and reduce the risk of injury.",
    quote: 'Strength is the foundation of any sports practice, defined as the ability to exert force to overcome resistance. It is a fundamental component of physical capacity, essential for performance enhancement and injury reduction',
    author:'Gregory Haff & N. Travis Triplett',
    text2:'Strength concepts within our programs:',
    cardDetail: "Movement efficiency, muscle strength, muscle power & injury prevention.",
    bullets: [
      'Movement efficiency',
      'Muscle strength',
      'Muscle power',
      'Injury prevention',
    ]
  },
  {
    id: 1,
    image1: IMAGES.pillars3,
    banner: IMAGES.service7,
    title: "Speed Development",
    title2: "Understanding Speed Development: The Key to Optimizing Athletic Performance",
    text1: "In the world of athletics, speed is often a defining factor that sets successful athletes apart from their competition. The capacity to accelerate, react swiftly, and maintain high velocities not only enhances an athlete’s overall performance but also contributes to their effectiveness in executing specific skills.",
    quote: 'Speed in athletic development refers to the ability of an athlete to move quickly across the ground or to perform a specific action rapidly. It is a crucial component of performance in many sports',
    author:'Thomas R. Baechle and Roger W. Earle',
    text2:'Exercises within our program:',
    cardDetail: "Running mechanics, Acceleration, Deceleration & Changes of direction.",
    bullets: [
      'Running mechanics',
      'Acceleration',
      'Deceleration',
      'Changes of direction',
      'Agility',
      'Top speed',
      'Jumping & Landing',
    ]
  },
  {
    id: 2,
    image1: IMAGES.pillars2,
    banner: IMAGES.service2,
    title: "Football Skills",
    title2: "Mastering Football Skills: Techniques for Game-Changing Performance",
    text1: "Skill mastery is crucial in football, encompassing the development of technical proficiency and tactical intelligence. It is the ability to execute precise movements, make quick decisions, and adapt to dynamic game situations, which are vital for success on the field.",
    quote: 'Mastering these skills not only enhances performance but also boosts confidence and game awareness',
    author:'Johan Cruyff and Pep Guardiola',
    text2:'Skills Mastery (Football): Training and developing skills tailored for footballers such as:',
    cardDetail: "training and developing skills tailored for footballers",
    bullets: [
      'Position-specific skills',
      'Controlling',
      'Passing',
      'Shooting',
      'Dribbling',
      'Defending & Attacking actions',
    ]
  },
  {
    id: 3,
    image1: IMAGES.pillars4,
    banner: IMAGES.service3,
    title: "Neuroscience In Sports",
    title2: "Neuroscience in Sports: Its Impact on Modern Sports Performance",
    text1: "Neuroscience in sports is the application of neuroscience principles to improve athletic performance, enhance training methods, and understand the brain's role in physical activity and sports-related behaviours.",
    quote: 'It involves studying how the brain and nervous system influence movement, coordination, reaction times, decision-making, memory, scanning, and other aspects critical to athletic performance',
    author:'Altenmüller, Edvinsson, et al., 2016',
    text2:'Exercises within our program:',
    cardDetail: "scanning, decision-making, memory retention & coordination.",
    bullets: [
      "Scanning",
      "Decision-Making",
      "Memory retention",
      "Coordination",
      "Reaction times",
      "Cognitive functions",
      "Visual processing speed",
      "Alertness", 

    ]
  },
];