import React from "react";
import { Link, useParams } from "react-router-dom";
import { IMAGES } from "../constants/theme";
import PageTitle from "../elements/PageTitle";
import { servicesData as services } from "../constants/data";
import Pricing from "./Pricing";

const ServicesDetails = () => {
  const { id } = useParams();
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle
          activePage={services[id]?.title}
          parentTitle="Services"
          to="services"
        />
        <div
          className="content-inner "
          style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7 order-lg-1">
                <div className="dz-media m-b30">
                  <img src={services[id].banner} alt={services[id].description} style={{
                    width: "770px",
                    height: "450px",
                    objectFit: "cover",
                  }} />
                </div>
                <div className="dz-content">
                  <div className="m-b40">
                    <h2 className="title m-b15">{services[id]?.title}</h2>
                    <p>{services[id]?.description}</p>
                    {/* <p>
                      We offer the best support and service you can imagine. Use
                      our support forum if you have any questions and our team
                      will respond. We have thousands of solved threads and a
                      customer satisfaction of 97%. We do care that your site
                      runs great!
                    </p> */}
                  </div>

                  <div className="m-b40">
                    {services[id]?.descriptionList?.length > 0 && (
                      <h4 className="m-b15">Program Overview</h4>
                    )}
                    <ul className="list-check-2 m-b30">
                      {services[id]?.descriptionList?.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    <p>
                      We provide unparalleled performance support to ensure your
                      success. Our dedicated team is available 24/7 to address
                      any questions or challenges you may encounter. We’re
                      committed to keeping your performance at its peak, so you
                      can focus on achieving your goals!
                    </p>
                  </div>

                  {/* Pay now section */}
                  {/* <section className="call-action style-1 m-b30">
                    <div className="container">
                      <div
                        className="inner-content wow fadeInUp"
                        data-wow-delay="0.8s"
                      >
                        <div className="row justify-content-between align-items-center">
                          <div className="text-center text-lg-start col-xl-6 m-lg-b20">
                            <h2 className="title">
                              {services[id]?.paid
                                ? "Get Started"
                                : "Contact Us"}
                            </h2>
                            <p>
                              {services[id]?.paid
                                ? "See our pricing plans and choose the one that best fits your needs."
                                : "Contact us for pricing and program details."}
                            </p>
                          </div>

                          <div className="text-center text-lg-end col-xl-6">
                            <div className="input-group-addon">
                              <button
                                name="submit"
                                type="submit"
                                className="btn btn-secondary btn-lg btn-skew"
                                onClick={() => {
                                  if (services[id]?.paid) {
                                    alert("Get Started");
                                  } else {
                                    alert("Contact Us");
                                  }
                                }}
                              >
                                <span>
                                  {services[id]?.paid
                                    ? "Get Started"
                                    : "Contact Us"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section> */}
                  {services[id]?.paid ? (
                    <Pricing plans={services[id]?.plans} />
                  ) : (
                    <>
                      <Link
                        to={"/contact-us"}
                        className="btn btn-primary btn-skew appointment-btn m-b30"
                      >
                        <span>Contact us for pricing and program details</span>
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <br />
              <br />
              <div className="col-xl-4 col-lg-5 m-b30">
                <aside className="side-bar left sticky-top">
                  {/* Widget de contacto o pago */}
                  <div className="widget_contact m-b30">
                    <div className="widget-content">
                      <div className="icon-bx">
                        <i className="flaticon-contact-center"></i>
                      </div>
                      <h4>Do you need any help?</h4>
                      <div
                        className="phone-number"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        +61-423-738-161
                      </div>
                      <div
                        className="phone-number"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        +61-478-940-397
                      </div>
                      <h6 className="email">info@performancehub.com.au</h6>
                      <div className="link-btn">
                        <Link
                          to={"/contact-us"}
                          className="btn btn-dark btn-skew"
                        >
                          <span>Contact Us</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="widget service_menu_nav">
                    <ul>
                      {services?.map((item, index) => (
                        <li
                          className={`${
                            id === index.toString() ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link to={`/services-details/${index}`}>
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <svg
                      width="250"
                      height="70"
                      viewBox="0 0 250 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 38L250 0L210 70L0 38Z"
                        fill="url(#paint0_linear_306_1296)"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_306_1296"
                          x1="118.877"
                          y1="35.552"
                          x2="250.365"
                          y2="35.552"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="1" stopColor="var(--primary)"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesDetails;
